import axios from '@/common/axios'
import qs from 'qs'

export function addTitrant (data) {
  return axios({
    method: 'post',
    url: '/quality/titrant/add',
    data: qs.stringify(data)
  })
}

export function deleteTitrant (id) {
  return axios({
    method: 'delete',
    url: '/quality/titrant/delete/' + id
  })
}

export function updateTitrant (data) {
  return axios({
    method: 'put',
    url: '/quality/titrant/update',
    data: qs.stringify(data)
  })
}

export function selectTitrantInfo (id) {
  return axios({
    method: 'get',
    url: '/quality/titrant/info/' + id
  })
}

export function selectTitrantList (query) {
  return axios({
    method: 'get',
    url: '/quality/titrant/list',
    params: query
  })
}
