<template>
  <div id="titrant">
    <el-dialog
      :title="titrantFormTitle"
      width="1200px"
      :visible.sync="titrantDialogVisible"
      :close-on-click-modal="false"
      @close="titrantDialogClose"
    >
      <el-form
        ref="titrantFormRef"
        :model="titrantForm"
        :rules="titrantFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input v-model="titrantForm.name" placeholder="请输入名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="浓度" prop="concentration">
              <el-input v-model="titrantForm.concentration" placeholder="请输入浓度" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配置体积" prop="volume">
              <el-input v-model="titrantForm.volume" placeholder="请输入配置体积" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <p>配制过程</p>
        <el-row>
          <el-col :span="8">
            <el-form-item label="基准物名称" prop="referenceName">
              <el-input v-model="titrantForm.referenceName" placeholder="请输入基准物名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="指示剂名称" prop="indicatorName">
              <el-input v-model="titrantForm.indicatorName" placeholder="请输入指示剂名称" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
              <el-form-item prop="claim">
                <el-input v-model="claim" 
                    type="textarea"
                    :rows="5" 
                    clearable readonly/>
              </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="步骤" prop="step">
              <el-input v-model="titrantForm.step" 
                    type="textarea"
                    :rows="5"  
                    placeholder="请输入步骤" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="配制人" prop="preparer">
              <el-input v-model="titrantForm.preparer" placeholder="请输入配制人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="配制日期" prop="preparationDate">
              <el-date-picker v-model="titrantForm.preparationDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
        <p>仪器型号及编号</p>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电子天平型号" prop="electronicBalanceModel">
              <el-input v-model="titrantForm.electronicBalanceModel" placeholder="请输入电子天平型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="电子天平编号" prop="electronicBalanceNo">
              <el-input v-model="titrantForm.electronicBalanceNo" placeholder="请输入电子天平编号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="仪器名称" prop="equipmentName">
              <el-input v-model="titrantForm.equipmentName" placeholder="请输入仪器名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="型号" prop="model">
              <el-input v-model="titrantForm.model" placeholder="请输入型号" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="编号" prop="no">
              <el-input v-model="titrantForm.no" placeholder="请输入编号" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <p>基准物恒重记录</p>
        <el-row>
          <el-col :span="24">
                <el-form-item label="基准物恒重记录" prop="benchmark">
                    <el-input v-model="titrantForm.benchmark" 
                        type="textarea"
                        :rows="5"   
                        placeholder="请输入基准物恒重记录" 
                        clearable />
                </el-form-item>
          </el-col>
        </el-row>
        <p>标定步骤</p>
        <el-row>
          <el-col :span="24">
                <el-form-item label="标定步骤" prop="calibrationStep">
                    <el-input v-model="titrantForm.calibrationStep" 
                        type="textarea"
                        :rows="5"   
                        placeholder="请输入标定步骤" 
                        clearable />
                </el-form-item>
          </el-col>
        </el-row>
        <p>标定步骤</p>
        <el-row>
          <el-col :span="12">
                <el-form-item label="初标记录与计算" prop="initialBidRecord">
                    <el-input v-model="titrantForm.initialBidRecord" 
                        type="textarea"
                        :rows="5"   
                        placeholder="请输入初标记录与计算" 
                        clearable />
                </el-form-item>
          </el-col>
          <el-col :span="12">
                <el-form-item label="复标记录与计算" prop="replyRecord">
                    <el-input v-model="titrantForm.replyRecord" 
                        type="textarea"
                        :rows="5"   
                        placeholder="请输入复标记录与计算" 
                        clearable />
                </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="初标室温" prop="initialTemperature">
              <el-input v-model="titrantForm.initialTemperature" placeholder="请输入初标室温" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复标室温" prop="replyTemperature">
              <el-input v-model="titrantForm.replyTemperature" placeholder="请输入复标室温" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="4">
              <el-form-item label="C=" prop="initialC">
              <el-input v-model="titrantForm.initialC" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="F=" prop="initialF">
              <el-input v-model="titrantForm.initialF" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="D％=" prop="initialD">
              <el-input v-model="titrantForm.initialD" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="C=" prop="replyC">
              <el-input v-model="titrantForm.replyC" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="F=" prop="replyF">
              <el-input v-model="titrantForm.replyF" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="D％＝" prop="replyD">
              <el-input v-model="titrantForm.replyD" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="标定人" prop="calibrator">
              <el-input v-model="titrantForm.calibrator" placeholder="请输入标定人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="标定日期" prop="calibrationDate">
              <el-date-picker v-model="titrantForm.calibrationDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="复标人" prop="replier">
              <el-input v-model="titrantForm.replier" placeholder="请输入复标人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="复标日期" prop="replyDate">
              <el-date-picker v-model="titrantForm.replyDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
        <p>结论：</p>
        <el-row>
          <el-col :span="4">
              <el-form-item label="C=" prop="conclusionC">
              <el-input v-model="titrantForm.conclusionC" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="F=" prop="conclusionF">
              <el-input v-model="titrantForm.conclusionF" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="4">
              <el-form-item label="D％＝" prop="conclusionD">
              <el-input v-model="titrantForm.conclusionD" placeholder="请输入" clearable />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="6">
            <el-form-item label="标定人" prop="conclusionCalibrator">
              <el-input v-model="titrantForm.conclusionCalibrator" placeholder="请输入标定人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="标定日期" prop="conclusionCalibrationDate">
              <el-date-picker v-model="titrantForm.conclusionCalibrationDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="复标人" prop="conclusionReplier">
              <el-input v-model="titrantForm.conclusionReplier" placeholder="请输入复标人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="复标日期" prop="conclusionReplyDate">
              <el-date-picker v-model="titrantForm.conclusionReplyDate" placeholder="请选择日期" type="date" value-format="yyyy-MM-dd HH:mm:ss" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="titrantDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="titrantFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.deviceNo" placeholder="请输入设备编号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="titrantPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="name" label="名称" />
      <el-table-column prop="concentration" label="浓度" />
      <el-table-column prop="volume" label="配置体积" />
      <el-table-column prop="referenceName" label="基准物名称" />
      <el-table-column prop="indicatorName" label="指示剂名称" />
      <el-table-column prop="step" label="步骤" />
      <el-table-column prop="preparer" label="配制人" />
      <el-table-column prop="preparationDate" label="配制日期" />
      <el-table-column prop="electronicBalanceModel" label="电子天平型号" />
      <el-table-column prop="electronicBalanceNo" label="电子天平编号" />
      <el-table-column prop="equipmentName" label="仪器名称" />
      <el-table-column prop="model" label="型号" />
      <el-table-column prop="no" label="编号" />
      <el-table-column prop="benchmark" label="基准物恒重记录" />
      <el-table-column prop="calibrationStep" label="标定步骤" />
      <el-table-column prop="initialBidRecord" label="初标记录与计算" />
      <el-table-column prop="initialTemperature" label="初标室温" />
      <el-table-column prop="initialC" label="初标c" />
      <el-table-column prop="initialF" label="初标f" />
      <el-table-column prop="initialD" label="初标d" />
      <el-table-column prop="replyRecord" label="复标记录与计算" />
      <el-table-column prop="replyTemperature" label="复标室温" />
      <el-table-column prop="replyC" label="复标c" />
      <el-table-column prop="replyF" label="复标f" />
      <el-table-column prop="replyD" label="复标d" />
      <el-table-column prop="calibrator" label="标定人" />
      <el-table-column prop="calibrationDate" label="标定日期" />
      <el-table-column prop="replier" label="复标人" />
      <el-table-column prop="replyDate" label="复标日期" />
      <el-table-column prop="conclusionC" label="结论c" />
      <el-table-column prop="conclusionF" label="结论f" />
      <el-table-column prop="conclusionD" label="结论d" />
      <el-table-column prop="conclusionCalibrator" label="结论标定人" />
      <el-table-column prop="conclusionCalibrationDate" label="结论标定日期" />
      <el-table-column prop="conclusionReplier" label="结论复标人" />
      <el-table-column prop="conclusionReplyDate" label="结论复标日期" />

      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="titrantPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addTitrant, deleteTitrant, updateTitrant, selectTitrantInfo, selectTitrantList } from '@/api/quality/titrant'

export default {
  data () {
    return {
      titrantDialogVisible: false,
      titrantFormTitle: '',
      titrantForm: {
        id: '',
        name: '',
        concentration: '',
        volume: '',
        referenceName: '',
        indicatorName: '',
        step: '',
        preparer: '',
        preparationDate: '',
        electronicBalanceModel: '',
        electronicBalanceNo: '',
        equipmentName: '',
        model: '',
        no: '',
        benchmark: '',
        calibrationStep: '',
        initialBidRecord: '',
        initialTemperature: '',
        initialC: '',
        initialF: '',
        initialD: '',
        replyRecord: '',
        replyTemperature: '',
        replyC: '',
        replyF: '',
        replyD: '',
        calibrator: '',
        calibrationDate: '',
        replier: '',
        replyDate: '',
        conclusionC: '',
        conclusionF: '',
        conclusionD: '',
        conclusionCalibrator: '',
        conclusionCalibrationDate: '',
        conclusionReplier: '',
        conclusionReplyDate: ''
      },
      titrantFormRules: {
        volume: [{ required: true, message: '取样点不能为空', trigger: ['blur', 'change']}]
      },
      titrantPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        deviceNo: ''
      },
      claim: '要求：\n校正因子 F=0.95～1.05；\n3份平行试验结果的相对平均偏差，除另有规定外，不得大于0.1%；\n初标平均值和复标平均值的相对平均偏差也不得大于0.1%；\n标定结果按初、复标的平均值计算q，取4位有效数字。'
    }
  },
  created () {
    selectTitrantList(this.searchForm).then(res => {
      this.titrantPage = res
    })
  },
  methods: {
    titrantDialogClose () {
      this.$refs.titrantFormRef.resetFields()
      this.titrantForm.id = ''
    },
    titrantFormSubmit () {
      if (this.titrantFormTitle === '滴定液配制、标定(复标)') {
        this.titrantDialogVisible = false
        return
      }
      this.$refs.titrantFormRef.validate(async valid => {
        if (valid) {
          if (this.titrantFormTitle === '新增滴定液配制、标定(复标)') {
            await addTitrant(this.titrantForm)
          } else if (this.titrantFormTitle === '修改滴定液配制、标定(复标)') {
            await updateTitrant(this.titrantForm)
          }
          this.titrantPage = await selectTitrantList(this.searchForm)
          this.titrantDialogVisible = false
        }
      })
    },
    handleAdd () {
        console.log(1)
      this.titrantFormTitle = '新增滴定液配制、标定(复标)'
      console.log(2)
      this.titrantDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteTitrant(row.id)
        if (this.titrantPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.titrantPage = await selectTitrantList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.titrantFormTitle = '修改滴定液配制、标定(复标)'
      this.titrantDialogVisible = true
      this.selectTitrantById(row.id)
    },
    handleInfo (index, row) {
      this.titrantFormTitle = '滴定液配制、标定(复标)详情'
      this.titrantDialogVisible = true
      this.selectTitrantById(row.id)
    },
    selectTitrantById (id) {
      selectTitrantInfo(id).then(res => {
        this.titrantForm.id = res.id
        this.titrantForm.name = res.name
        this.titrantForm.concentration = res.concentration
        this.titrantForm.volume = res.volume
        this.titrantForm.referenceName = res.referenceName
        this.titrantForm.indicatorName = res.indicatorName
        this.titrantForm.step = res.step
        this.titrantForm.preparer = res.preparer
        this.titrantForm.preparationDate = res.preparationDate
        this.titrantForm.electronicBalanceModel = res.electronicBalanceModel
        this.titrantForm.electronicBalanceNo = res.electronicBalanceNo
        this.titrantForm.equipmentName = res.equipmentName
        this.titrantForm.model = res.model
        this.titrantForm.no = res.no
        this.titrantForm.benchmark = res.benchmark
        this.titrantForm.calibrationStep = res.calibrationStep
        this.titrantForm.initialBidRecord = res.initialBidRecord
        this.titrantForm.initialTemperature = res.initialTemperature
        this.titrantForm.initialC = res.initialC
        this.titrantForm.initialF = res.initialF
        this.titrantForm.initialD = res.initialD
        this.titrantForm.replyRecord = res.replyRecord
        this.titrantForm.replyTemperature = res.replyTemperature
        this.titrantForm.replyC = res.replyC
        this.titrantForm.replyF = res.replyF
        this.titrantForm.replyD = res.replyD
        this.titrantForm.calibrator = res.calibrator
        this.titrantForm.calibrationDate = res.calibrationDate
        this.titrantForm.replier = res.replier
        this.titrantForm.replyDate = res.replyDate
        this.titrantForm.conclusionC = res.conclusionC
        this.titrantForm.conclusionF = res.conclusionF
        this.titrantForm.conclusionD = res.conclusionD
        this.titrantForm.conclusionCalibrator = res.conclusionCalibrator
        this.titrantForm.conclusionCalibrationDate = res.conclusionCalibrationDate
        this.titrantForm.conclusionReplier = res.conclusionReplier
        this.titrantForm.conclusionReplyDate = res.conclusionReplyDate

      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectTitrantList(this.searchForm).then(res => {
        this.titrantPage= res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectTitrantList(this.searchForm).then(res => {
        this.titrantPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectTitrantList(this.searchForm).then(res => {
        this.titrantPage = res
      })
    }
  }
}
</script>

<style>
</style>
